import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Tooltip, MenuItem } from '@mui/material'
import axios from 'axios'
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useParams } from 'react-router-dom';

export default function SettingCarFuelDialogCreate({ onClose, open, getData }) {
    const schema = Yup.object().shape({
        car_fuel_name: Yup.string().required('required'),
    });
    // const handleSnackbar = ({ variant, message }) => {
    //     enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'center', vertical: 'bottom' } });
    // };
    const formik = useFormik({
        initialValues: {
            car_fuel_name: ""
        },
        validationSchema: schema,
        onSubmit: async (values,{resetForm}) => {
            axios.post("https://api.sitbooking.com/booking/car_fuel", { name: values.car_fuel_name})
                .then(res => {
                    onClose()
                    getData()
                    resetForm()
                })
                .catch(error => {
                    console.log('error', error)
                })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, submitForm, setFieldValue } = formik;


    return (
        <div>
            <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm" >
                <DialogTitle>สร้างข้อมูลประเภทรถ</DialogTitle>
                <DialogContent>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={1} marginTop={"5px"}>
                                <Grid item xs={12}>
                                    <TextField size="small" fullWidth label="ชื่อประเภทเชื้อเพลิง"
                                        {...getFieldProps('car_fuel_name')}
                                        error={Boolean(touched.car_fuel_name && errors.car_fuel_name)}
                                        helperText={touched.car_fuel_name && errors.car_fuel_name}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={submitForm} variant="outlined">Save</Button>
                    <Button onClick={onClose} variant="outlined">Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
