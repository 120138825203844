import axios from 'axios';
import CryptoJS from 'crypto-js';

import { createCipher, randomBytes } from 'crypto';
import { Buffer } from 'buffer';


const aesEncryptECBHex = (plaintext) => {
    const password = "vIOm4x1FCctkwx0K"
    const key = CryptoJS.enc.Utf8.parse(password.padEnd(16, ' '));
    const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex)
};



function convertToJSON(data) {

    return data.slice(1).map(row => {
        return row.reduce((acc, value, index) => {
            acc[data[0][index]] = data[0][index] === "vehicle_count" ? JSON.parse(value)?.reduce((acc, item) => acc + parseInt(item.count), 0) : (data[0][index] === "booking" || data[0][index] === "event" || data[0][index] === "data") ? JSON.parse(value) : (value !== 'null' ? value : '');
            // acc[data[0][index]] = data[0][index] === "vehicle_count" ? JSON.parse(value)?.reduce((acc, item) => acc + parseInt(item.count), 0) : value;
            return acc;
        }, {});
    });
}


const aesApi = axios.create({
    // transformRequest: [
    //     (data) => {
    //         return new URLSearchParams(data).toString();
    //     },
    // ],
    headers: {
    },
    transformResponse: [
        (res) => {
            try {
                const result = JSON.parse(res)[0]?.result || []
                if (result.length > 0) {
                    return convertToJSON(result)
                }
                return [];
            } catch (error) {
                try {
                    return JSON.parse(res);
                } catch (error) {
                    return res;
                }
            }
        },
    ],
});

export { aesEncryptECBHex, aesApi }
