import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Tooltip } from '@mui/material'
import DialogEventsEmployee from '../DialogEventsEmployee';
import DialogViewWork from '../DialogViewWork';
import _ from 'lodash';
import { fDateTo } from '../../../utils/formatTime';

export default function TableOverViewCars({
    dataFillter, handleEventClick, maxDate, year, month, open, userId
}) {

    const [dataSelect, setDataSelect] = useState("")
    const [dateBetween, setDateBetween] = useState({
        vehicle_id: "",
        date_start: "",
        date_end: ""
    });

    const onSelect = ({ vehicle_id, driver_id }) => {
        console.log('driver_id-d', driver_id)
        handleEventClick(vehicle_id, driver_id)
    }




    const getBackgroundColor = (date, vehicle_id, ranges) => {

        const event = ranges?.find((event) => {
            const start = new Date(event.date_start);
            const end = new Date(event.date_end);
            const dateToCheck = new Date(date);
            return dateToCheck > start && dateToCheck <= end || event.date_start.split(" ")[0] === date.split(" ")[0]
        }
        );
        return event ? event?.color : (dateBetween.date_start !== "" && dateBetween.vehicle_id === vehicle_id) ? date >= dateBetween.date_start && date <= (dateBetween.date_end !== "" ? dateBetween.date_end : dateBetween.date_start) ? "#e9e6ee" : 'white' : 'white';
    };



    const filterEventInfo = (date, ranges) => {
        if (ranges.length > 0) {
            return ranges?.filter((event) => {
                const start = new Date(event.date_start);
                const end = new Date(event.date_end);
                const dateToCheck = new Date(date);
                return dateToCheck > start && dateToCheck <= end || event.date_start.split(" ")[0] === date.split(" ")[0]
            })
        } else {
            return []
        }
    };

    const [isOpenEvents, setIsOpenEvents] = useState(false);

    const handleEventsOpen = (data) => {
        setIsOpenEvents(true)
    }
    const handleEventsClose = () => {
        setIsOpenEvents(false)
    }
    const handleEventsCancel = () => {
        setIsOpenEvents(false)
    }

    const [isOpenWork, setIsOpenWork] = useState(false)
    const [dataViewWork, setDataViewWork] = useState([])
    const handleViewWorkClose = () => {
        setIsOpenWork(false)
    }
    const handleEscClose = () => {
        setIsOpenWork(false)
    }
    return (
        <>
            <DialogEventsEmployee userId={userId} open={isOpenEvents} onCancel={handleEventsCancel} onClose={handleEventsClose} data={dateBetween} />
            <DialogViewWork userId={userId} open={isOpenWork} onCloseEsc={handleEscClose} onClose={handleViewWorkClose} data={dataViewWork} />
            {
                dataFillter.map((rowdata, index) => {
                    console.log('rowdata', rowdata)
                    return (
                        <TableRow key={`car-row-${index}`}>
                            <TableCell sx={{ position: 'sticky', padding: "0px", whiteSpace: "nowrap", left: 0, zIndex: 1302, left: 0, backgroundColor: "white" }}>
                                {/* <Tooltip
                                    open={(true && open)}
                                    PopperProps={{ style: { zIndex: 0 } }}
                                    title={<BoxNameVehicleID vehicle_id={rowdata.vehicle_id} />}
                                    placement="top"
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [-5, -28],
                                                    },
                                                },
                                            ],
                                            style: { zIndex: 1301, padding: "0px", margin: "0px" }
                                        },
                                        tooltip: {
                                            sx: { zIndex: 1, backgroundColor: "white", borderRadius: "0px", userSelect: "none", marginBottom: "1px !important", padding: "0px !important", color: "black" }
                                        }
                                    }}
                                >
                                    <Box sx={{ width: "308px", height: "25px" }}> </Box>
                                </Tooltip> */}
                                <Box sx={{ width: "180px", height: "45px", textAlign: "center" }}>
                                    <BoxNameVehicleID vehicle_id={rowdata.vehicle_id} fname_lname={rowdata.fname_lname} />
                                </Box>
                            </TableCell>
                            {
                                (
                                    [...Array(maxDate)].map((value, index) => {
                                        const day = (index + 1).toString().padStart(2, "0")
                                        const data = filterEventInfo(`${year}-${month}-${day} 00:00:00`, rowdata.data)
                                        const dataDate = _.uniqBy(data.map(d => { return { "date_start": d.date_start?.split(" ")[0] } }), "date_start")
                                        return (
                                            <TableCell
                                                key={`car-TableCell-row-${index}`}
                                                onClick={() => {
                                                    if (data.length <= 0) {
                                                        const data = {
                                                            vehicle_id: rowdata.vehicle_id,
                                                            type_car: rowdata.standard,
                                                            date: `${year}-${month}-${day}`,
                                                            driver_id: rowdata.userid
                                                        }
                                                        console.log('rowdata-d', rowdata)
                                                        onSelect(data)
                                                    }
                                                    //  else {
                                                    //     setIsOpenWork(true)
                                                    //     setDataViewWork(filterEventInfo(`${year}-${month}-${day} 00:00:00`, rowdata.data))
                                                    // }
                                                }}
                                                sx={{
                                                    cursor: "pointer",
                                                    position: 'sticky',
                                                    whiteSpace: "nowrap",
                                                    left: 0,
                                                    zIndex: 1301,
                                                    padding: "0px",
                                                    backgroundColor: getBackgroundColor(`${year}-${month}-${day} 00:00:00`, rowdata.vehicle_id, rowdata.data),
                                                }}
                                            >
                                                {
                                                    data.length > 0 ? (
                                                        <Box position="relative">
                                                            <Tooltip
                                                                open={(dataDate.filter(d => d.date_start === `${year}-${month}-${day}`).length > 0 && open)}
                                                                title={<BoxName data={data.filter(d => d.date_start.split(" ")[0] === `${year}-${month}-${day}`)} />} placement="right"
                                                                PopperProps={{
                                                                    style: { zIndex: "1300 !important" }
                                                                }}

                                                                slotProps={{
                                                                    popper: {
                                                                        modifiers: [
                                                                            {
                                                                                name: 'offset',
                                                                                options: {
                                                                                    offset: [0, -47],
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                    tooltip: {
                                                                        sx: { userSelect: "none", cursor: "pointer", marginLeft: "1px !important", padding: "0px !important", borderRadius: "2px ", opacity: "0.8", borderColor: "white", border: "1px", borderStyle: "solid" }
                                                                    }
                                                                }}
                                                            >
                                                                <Box sx={{ width: "47.58px", height: "35px" }}>
                                                                </Box>
                                                            </Tooltip>
                                                        </Box>
                                                    ) :
                                                        (
                                                            <Box sx={{ width: "47.58px", height: "35px" }}></Box>
                                                        )
                                                }
                                            </TableCell>
                                        )
                                    })
                                )
                            }
                        </TableRow >
                    )
                })
            }
        </>
    )
}

const BoxName = ({ data }) => {
    const calculateDifference = (d) => {
        const start = new Date(d.date_start.split(" ")[0]);
        const end = new Date(d.date_end.split(" ")[0]);
        const differenceInMilliseconds = Math.abs(end - start);
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        return differenceInDays + 1;
    };
    return (
        <>
            <Box sx={{ maxHeight: "30px", overflow: "auto" }}>
                {data.map((d, i) => {
                    return (
                        <Box sx={{ maxWidth: `${43 * calculateDifference(d)}px`, whiteSpace: "nowrap" }}>
                            <Box>
                                {`${d.event_name || d.company_code || d.booking_id}`} {`${d.event_name !== '' && d.booking_id === undefined ? d?.note !== '' ? `(${d?.note})` : "" : ""}`}
                            </Box>
                            {
                                d?.drivers?.map((driver, index) => {
                                    return (
                                        <Typography fontSize="10px">
                                            {`${driver.fname_lname}`}
                                        </Typography>
                                    )
                                })
                            }
                            <Typography fontSize="10px">
                                {`in : ${fDateTo(d.reserve_datetime_start !== undefined ? d.reserve_datetime_start : d.date_start, "HH:mm")} - out : ${fDateTo(d.reserve_datetime_end !== undefined ? d.reserve_datetime_end : d.date_end, "HH:mm")}`}
                            </Typography>
                        </Box>
                    )
                })}
            </Box >
        </>
    )
}


const BoxNameVehicleID = ({ vehicle_id, fname_lname }) => {

    return (
        <Box sx={{ width: "180px", height: "45px", fontSize: "18px", fontWeight: "40", justifyContent: "center", overflow: "hidden" }}>
            <Box sx={{ paddingTop: "5px" }}>
                <Typography>
                    {`${vehicle_id}`}
                </Typography>
                <Typography fontSize="10px">
                    {`${fname_lname !== '' ? `(${fname_lname})` : ''}`}
                </Typography>
            </Box>
        </Box >
    )
}