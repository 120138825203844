import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import { Api, Apimongodb } from '../../components/api';
import { useState } from 'react';
import _ from 'lodash';
// import PoiDialogsItems from './PoiDialogsItems';

import { List, AutoSizer } from 'react-virtualized';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Virtuoso } from 'react-virtuoso';

import { getComparator, stableSort } from '../poi/GetComparator';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        // minWidth: "80vw",
        // maxWidth: "80vw",
        minHeight: "40vh",
        maxHeight: "40vh"
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function PoiDialogs({ open, handleClose, onChanges, cConame }) {


    const [poigeolist, setpoigeolist] = useState([]);
    const [poigeolistshow, setpoigeolistshow] = useState([]);
    const [listGroupPoi, setListGroupPoi] = useState([])
    const [poigeolistSelect, setpoigeolistSelect] = useState([]);




    React.useEffect(() => {
        Apimongodb.get(`/Tracking/poigeocodeall?cname=${cConame}`)
            .then(res => {
                setpoigeolist(res.data.result)
                // setpoigeolistSelect(select)
                onGetPoigeocodelist(res.data.result, true)
            })
            .catch(err => {
                console.log('err', err)
            })

        Api.get(`/Tracking/poigeocodegroups?cname=${cConame}`)
            .then(res => {
                const data = res.data
                // setListGroupPoi(data)
                setListGroupPoi(data.concat(
                    {
                        "group_id": "",
                        "name": "ไม่มีกลุ่ม"
                    }
                ))
            })
            .catch(err => {
                setListGroupPoi([].concat(
                    {
                        "group_id": "",
                        "name": ""
                    }
                ))
            })
    }, [])

    const onGetPoigeocodelist = (result, firsttime) => {
        const select = result.map(v => v.poi_id)
        if (firsttime) {
            // setpoigeolistSelect([])
            // setpoigeolistSelect(select)
        }
        var data = JSON.stringify({
            "cname": cConame,
            "poi_id": select
        });
        Apimongodb.post(`/Tracking/poigeocodelist`, data)
            .then(res => {
                setpoigeolistshow(res.data.result)
            })
            .catch(err => {
                console.log('err', err)
            })
    }

    const onChangeSelect = ({ mode, select }) => {
        if (mode === "select") {
            const ndata = poigeolistSelect.concat(select)
            setpoigeolistSelect(ndata)
        } else {
            const ndata = poigeolistSelect.filter(v => v !== select)
            setpoigeolistSelect(ndata)
        }
    }
    const groupPoiShow = (mode, data) => {
        if (mode === "select") {
            const ndata = poigeolistSelect.concat(data)
            setpoigeolistSelect(ndata)
        }
        if (mode === "remove") {
            const ndata = poigeolistSelect.filter((item) => {
                return data.indexOf(item) === -1
            })
            setpoigeolistSelect(ndata)

        }
    }

    const handleOnChanges = () => {
        const show = poigeolistSelect.map(id => {
            return poigeolistshow.find(v => { return v.poi_id === id })
        })
        onChanges(show)
        handleClose()
    }

    return (
        <StyledDialog
            onClose={handleOnChanges}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                POI
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleOnChanges}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{
                minWidth: "350px",
                // minHeight: "800px"
            }}>
                <PoiDialogsItems groupPoiShow={groupPoiShow} poigeolistshow={poigeolistshow} onChangeSelect={onChangeSelect} listGroupPoi={listGroupPoi} poigeolist={poigeolist} poigeolistSelect={poigeolistSelect} />
            </DialogContent>
        </StyledDialog>
    );
}







const PoiDialogsItems = ({ poigeolist, onChangeSelect, poigeolistSelect, listGroupPoi, groupPoiShow }) => {
    const onSelectByGroup = ({ mode, group }) => {
        if (group !== '') {
            const data = poigeolist.filter(x => x.group_id === group).map(v => v.poi_id)
            if (mode === "select") {
                groupPoiShow("select", data)
            }
            if (mode === "remove") {
                groupPoiShow("remove", data)
            }
        } else {
            const isNoGroup = (group) => {
                const data = listGroupPoi.filter(x => x.group_id === group)
                return data.length <= 0
            }
            const data = poigeolist.filter(x => (isNoGroup(x.group_id) || x.group_id === "")).map(v => v.poi_id)
            if (mode === "select") {
                groupPoiShow("select", data)
            }
            if (mode === "remove") {
                groupPoiShow("remove", data)
            }
        }
    }
    const groupedData = [...(new Set([...listGroupPoi.map(v => {
        const d = ([].concat(v)).concat(poigeolist.filter(x => x.group_id === v.group_id))
        return d
    }).flat(), ...poigeolist]))]



    return (
        <Box>
            <Virtuoso
                style={{ height: `calc(100vh - 164px)` }}
                data={groupedData}
                itemContent={(index, data) => {
                    return (
                        <RowConContent
                            data={data}
                            poigeolistSelect={poigeolistSelect}
                            onChangeSelect={onChangeSelect}
                            onSelectByGroup={onSelectByGroup}
                        />
                    )
                }
                }
            />
        </Box>
    );
};


const RowConContent = ({
    data,
    poigeolistSelect,
    onChangeSelect,
    onSelectByGroup
}) => {
    const numberOfKeys = Object.keys(data).length
    const isGroupHeader = numberOfKeys === 2
    const [view, setView] = useState(false)


    return (
        <Box>
            {isGroupHeader ? (
                <Stack direction="row" spacing={1} justifyContent="space-between">
                    <Box sx={{ width: `calc(100% - 60px)`, maxWidth: `calc(100% - 60px)`, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontWeight: "bold", fontSize: "16px", cursor: "pointer" }}>
                        {data.name}
                    </Box>
                    <Box>
                        <Stack direction="row" spacing={1} justifyContent="end">
                            {
                                view ?
                                    <IconButton size="small" onClick={() => {
                                        setView(false)
                                        onSelectByGroup({ mode: "remove", group: data.group_id })
                                    }} >
                                        <VisibilityOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                    :
                                    <IconButton size="small" onClick={() => {
                                        setView(true)
                                        onSelectByGroup({ mode: "select", group: data.group_id })
                                    }} >
                                        <VisibilityOffOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                            }
                        </Stack>
                    </Box>
                </Stack>
            ) :
                (
                    <>
                        <Stack direction="row" spacing={1} justifyContent="end">
                            <Box sx={{ width: `calc(100% - 60px)`, maxWidth: `calc(100% - 60px)`, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", cursor: "pointer" }}
                            >
                                {data.poi_name}
                            </Box>
                            <Box>
                                <Stack direction="row" spacing={1}>
                                    {
                                        poigeolistSelect.indexOf(data.poi_id) >= 0 ?
                                            <IconButton size="small" onClick={() => { onChangeSelect({ mode: "remove", select: data.poi_id }) }} ><VisibilityOutlinedIcon fontSize="inherit" /></IconButton>
                                            :
                                            <IconButton size="small" onClick={() => { onChangeSelect({ mode: "select", select: data.poi_id }) }} ><VisibilityOffOutlinedIcon fontSize="inherit" /></IconButton>
                                    }
                                </Stack>
                            </Box>
                        </Stack>
                    </>
                )
            }
        </Box>
    )
}
