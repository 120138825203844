import React, { useEffect, useState } from 'react'

import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { styled } from '@mui/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import ViewListTwoToneIcon from '@mui/icons-material/ViewListTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
import { fDateTo } from '../../utils/formatTime';
import { aesApi, aesEncryptECBHex } from '../../query/QueryAES';



import { atom, useRecoilState } from 'recoil';

const searchStartDateAtom = atom({
  key: 'searchStartDate',
  default: fDateTo(new Date(), "yyyy-MM-dd"),
});

const searchEndDateAtom = atom({
  key: 'searchEndDate',
  default: fDateTo(new Date(), "yyyy-MM-dd"),
});

export default function HomeBooking({ userId }) {

  const [search, setSearch] = useState("")

  const [searchStartDate, setSearchStartDate] = useRecoilState(searchStartDateAtom)
  const [searchEndDate, setSearchEndDate] = useRecoilState(searchEndDateAtom)

  // const [searchStartDate, setSearchStartDate] = useState(fDateTo(new Date(), "yyyy-MM-dd"))
  // const [searchEndDate, setSearchEndDate] = useState(fDateTo(new Date(), "yyyy-MM-dd"))
  const [bookings, setBookings] = useState([])


  useEffect(() => {
    const req = {
      "database": "booking_prd",
      "data": aesEncryptECBHex(`SELECT booking_h.booking_id ,booking_h.booking_userid,booking_h.datetime_start, booking_h.datetime_end ,booking_h.type_group, booking_h.type_booking , booking_h.company_id,if_company.code as company_code,if_company.name as company_name,if_company.color as company_color, booking_h.guest_count as guest_count_h , booking_h.vehicle_list AS vehicle_count, COUNT(CASE WHEN booking_d.vehicle_id IS NOT NULL AND booking_d.vehicle_id != '' THEN 1 END) AS vehicle_count_book,STUFF(
        (SELECT ',' + trip_type
    FROM booking_trip
    WHERE booking_trip.booking_id = booking_h.booking_id
    FOR XML PATH('')), 1, 1, '') AS groups_code
     from booking_h left JOIN booking_trip ON booking_h.booking_id = booking_trip.booking_id left JOIN booking_d ON booking_trip.booking_id = booking_d.booking_id AND booking_trip.trip_id = booking_d.trip_id left JOIN if_company ON if_company.id = booking_h.company_id WHERE booking_h.datetime_start BETWEEN '${searchStartDate} 00:00:00' AND '${searchEndDate} 23:59:59' GROUP BY booking_h.booking_id,booking_userid,datetime_start,datetime_end,type_group,type_booking,company_id,if_company.code,if_company.name,if_company.color,booking_h.guest_count, booking_h.vehicle_list;`)
    }
    aesApi.post("https://sqlquery.sitbooking.com/query", req)
      .then(res => {
        // setData(res.data)[booking_prd].dbo.[company_id]
        if (res.data?.message === null) {
          setBookings([])
        } else {
          setBookings(res.data)
        }
      })
      .catch(err => {
        console.log('err', err)
      })
  }, [])

  const funcGetAllBooking = () => {
    const req = {
      "database": "booking_prd",
      "data": aesEncryptECBHex(`SELECT booking_h.booking_id ,booking_h.booking_userid,booking_h.datetime_start, booking_h.datetime_end ,booking_h.type_group, booking_h.type_booking , booking_h.company_id,if_company.code as company_code,if_company.name as company_name,if_company.color as company_color, booking_h.guest_count as guest_count_h , booking_h.vehicle_list AS vehicle_count, COUNT(CASE WHEN booking_d.vehicle_id IS NOT NULL AND booking_d.vehicle_id != '' THEN 1 END) AS vehicle_count_book,
        STUFF(
        (SELECT ',' + trip_type
    FROM booking_trip
    WHERE booking_trip.booking_id = booking_h.booking_id
    FOR XML PATH('')), 1, 1, '') AS groups_code
     from booking_h left JOIN booking_trip ON booking_h.booking_id = booking_trip.booking_id left JOIN booking_d ON booking_trip.booking_id = booking_d.booking_id AND booking_trip.trip_id = booking_d.trip_id left JOIN if_company ON if_company.id = booking_h.company_id WHERE booking_h.datetime_start BETWEEN '${searchStartDate} 00:00:00' AND '${searchEndDate} 23:59:59' GROUP BY booking_h.booking_id,booking_userid,datetime_start,datetime_end,type_group,type_booking,company_id,if_company.code,if_company.name,if_company.color,booking_h.guest_count, booking_h.vehicle_list;`)
    }
    aesApi.post("https://sqlquery.sitbooking.com/query", req)
      .then(res => {
        if (res.data?.message === null) {
          setBookings([])
        } else {
          setBookings(res.data)
        }
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  // const funcGetAllBooking = () => {
  //   axios.get(`https://booking.sitgps.com/booking/get_all_booking`)
  //     .then(res => {
  //       if (res.data?.message === null) {
  //         setBookings([])
  //       } else {
  //         setBookings(res.data)
  //       }
  //     })
  // }


  const navigation = useNavigate()

  const onCreate = () => {
    navigation("create")
  }
  const onOverView = () => {
    navigation("overview")
  }
  const onUpdate = (booking_id) => {
    navigation(`update/${booking_id}`)
  }
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleClickVariant = ({ message, variant }) => {
    enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

  };
  const handleSnackWithAction = ({ message, data, onHandle }) => {
    closeSnackbar()
    enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: "center",
        vertical: 'bottom'
      },
      variant: 'default',
      // style: { bgcolor: "whitesmoke !important" },
      action: (key) => {
        return (
          <React.Fragment>
            <Stack direction="row" spacing={1}>
              <Button
                size='small'
                color="error"
                variant="contained"
                onClick={() => {
                  closeSnackbar(key)
                  onHandle()
                }}
              >
                <CheckRoundedIcon fontSize="small" />
              </Button>
              <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                <CloseRoundedIcon fontSize="small" />
              </Button>
            </Stack>
          </React.Fragment>
        )
      }
    });
  };

  const onHandleBooking = (d) => {
    const data = {
      "booking_id": d.booking_id,
      "booking_userid": userId,
    }
    axios.delete(`https://booking.sitgps.com/booking/book_h`, { data: data })
      .then(res => {
        handleClickVariant({ message: "ลบข้อมูลสำเร็จ", variant: "success" })
        funcGetAllBooking()
      })
  }

  const handleChange = (e) => {
    const { value, name } = e.target
    setSearch(value)
  }
  const handleChangeSearchStartDate = (e) => {
    const { value, name } = e.target
    setSearchStartDate(value)
  }
  const handleChangeSearchEndDate = (e) => {
    const { value, name } = e.target
    setSearchEndDate(value)
  }

  const onNavToReport = () => {
    navigation("report")
  }
  const onNavToSettings = () => {
    navigation("settings")
  }


  const filter = bookings.filter((val) => val?.booking_id?.toUpperCase().includes(search.toUpperCase()) || val?.company_name?.toUpperCase().includes(search.toUpperCase()) || val?.groups_code?.toUpperCase().includes(search.toUpperCase()))
  return (
    <div>
      <Box sx={{ padding: "10px" }}>
        <Stack spacing={1}>
          <Stack spacing={1} direction="row" justifyContent="space-between">
            <Box>
              <Stack spacing={1} direction="row">
                <TextField size="small" label='ค้นหา'
                  value={search}
                  onChange={handleChange}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end" >
                //       <IconButton size="small"  ><SearchIcon fontSize="inherit" /></IconButton>
                //     </InputAdornment>
                //   )
                // }}
                />
                <TextField size="small"
                  type="date"
                  label='วันที่'
                  value={searchStartDate}
                  onChange={handleChangeSearchStartDate}
                />
                <TextField size="small"
                  type="date"
                  label='ถึงวันที่'
                  value={searchEndDate}
                  onChange={handleChangeSearchEndDate}
                />
                <Button variant="outlined" size="small" onClick={funcGetAllBooking}> ค้นหา </Button>
              </Stack>
            </Box>
            <Box>
              <Stack spacing={1} direction="row">
                <IconButton onClick={onCreate} ><PostAddOutlinedIcon /></IconButton>
                <IconButton onClick={onOverView} ><ViewListTwoToneIcon /></IconButton>
                <IconButton onClick={onNavToReport} ><AssessmentIcon /></IconButton>
                <IconButton onClick={onNavToSettings} ><SettingsIcon /></IconButton>
              </Stack>
            </Box>
          </Stack>
          <Box>
            <Typography variant="h6" sx={{ textAlign: "center" }}>{`ใบจอง (${filter.length})`}</Typography>
          </Box>
          <Box>
            <Table aria-label="booking table">
              <TableHead>
                <TableRow>
                  {/* <TableCell align="left" width={"120px"} sx={{ whiteSpace: "nowrap" }} >เลขที่ใบจอง</TableCell> */}
                  <TableCell align="left" width={"120px"} sx={{ whiteSpace: "nowrap" }}>ลูกค้า</TableCell>
                  <TableCell align="left" width={"120px"} sx={{ whiteSpace: "nowrap", maxWidth: "200px", overflow: "auto" }}>กรุ๊ปโค้ด</TableCell>
                  <TableCell align="left" width={"120px"} sx={{ whiteSpace: "nowrap" }}>วันที่เริ่ม</TableCell>
                  <TableCell align="left" width={"120px"} sx={{ whiteSpace: "nowrap" }}>วันที่จบ</TableCell>
                  {/* <TableCell align="right" width={"120px"} sx={{ whiteSpace: "nowrap" }}>รถที่ใช้งาน</TableCell> */}
                  {/* <TableCell align="right" width={"120px"} sx={{ whiteSpace: "nowrap" }} >รถที่ต้องการ</TableCell> */}
                  <TableCell align="center" width={"120px"} sx={{ whiteSpace: "nowrap" }} >{`จำนวนรถ`} <br /> {`(จัดแล้ว / ต้องการ)`}</TableCell>
                  <TableCell align="left" width={"120px"} sx={{ whiteSpace: "nowrap" }}>ประเภทงาน</TableCell>
                  <TableCell align="left" width={"120px"} sx={{ whiteSpace: "nowrap" }}>สัญชาติ</TableCell>
                  <TableCell align="left" width={"120px"}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.orderBy(filter, ['datetime_start', 'company_name', 'datetime_end'], ["asc", "asc", "asc"]).map((booking, index) => (
                  <TableRow key={index}>
                    {/* <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>{booking.booking_id}</TableCell> */}
                    <TableCell align="left" sx={{ whiteSpace: "nowrap", maxWidth: "200px", overflow: "auto" }}>{booking.company_name}</TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap", maxWidth: "200px", overflow: "auto" }}>{booking.groups_code}</TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>{`${fDateTo(booking.datetime_start, "dd/MM/yy HH:mm")}`}</TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>{`${fDateTo(booking.datetime_end, "dd/MM/yy HH:mm")}`}</TableCell>
                    {/* <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>{`${booking.vehicle_count_book}`}</TableCell> */}
                    {/* <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>{`${booking.vehicle_count}`}</TableCell> */}
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>{`(${booking.vehicle_count_book} / ${booking.vehicle_count})`}</TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>{booking.type_group}</TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>{booking.type_booking}</TableCell>
                    <TableCell align="ricenterght">
                      <Stack spacing={1} direction="row" justifyContent="end">
                        <IconButton onClick={() => onUpdate(booking.booking_id)} size="small"><EditNoteIcon /></IconButton>
                        <IconButton onClick={() => { handleSnackWithAction({ message: "ต้องการลบข้อมูลใบจอง", data: booking, onHandle: () => onHandleBooking({ booking_id: booking.booking_id }) }) }} size="small"><DeleteOutlineIcon /></IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Stack>
      </Box >
    </div >
  )
}
