import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Tooltip, MenuItem } from '@mui/material'
import axios from 'axios'
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { aesApi, aesEncryptECBHex } from '../../../query/QueryAES';

export default function SettingCarOptionDialogUpdate({ onClose, open, getData, data }) {

    const schema = Yup.object().shape({
        car_option_name: Yup.string().required('required')
    });
    // const handleSnackbar = ({ variant, message }) => {
    //     enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'center', vertical: 'bottom' } });
    // };
    const formik = useFormik({
        initialValues: {
            ...data
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            onClose()
            axios.put(`https://api.sitbooking.com/booking/car_option`, { ...values })
                .then(res => {
                    onClose()
                    getData()
                })
                .catch(error => {
                    console.log('error', error)
                })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, submitForm } = formik;


    return (
        <div>
            <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
                <DialogTitle>แก้ไขข้อมูลตัวเลือก</DialogTitle>
                <DialogContent>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={1} marginTop={"5px"}>
                                <Grid item xs={12} >
                                    <TextField size="small" fullWidth label="รหัสประเภทรถ"
                                        {...getFieldProps('car_option_id')}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField size="small" fullWidth label="ตัวเลือก"
                                        {...getFieldProps('car_option_name')}
                                        error={Boolean(touched.car_option_name && errors.car_option_name)}
                                        helperText={touched.car_option_name && errors.car_option_name}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={submitForm} variant="outlined">Save</Button>
                    <Button onClick={onClose} variant="outlined">Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
