import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Tooltip, MenuItem, FormGroup, FormControlLabel } from '@mui/material'
import axios from 'axios'
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { aesApi, aesEncryptECBHex } from '../../../query/QueryAES';

export default function SettingCarsDialogCreate({ onClose, open, getData }) {
    const [carsOption, setCarsOption] = useState({
        car_type: [],
        car_standard: [],
        car_seat_type: [],
        car_seat_size: [],
        car_option: [],
        car_fuel: [],
        car_color: [],
        car_color: []
    });

    useEffect(() => {
        axios.get(`https://api.sitbooking.com/booking/get_car_options`)
            .then(res => {
                setCarsOption({
                    ...res.data
                })
            })
            .catch(err => {
                console.log('err', err)
            })
    }, [])

    const schema = Yup.object().shape({
        vehicle_id: Yup.string().required('required')
    });
    // const handleSnackbar = ({ variant, message }) => {
    //     enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'center', vertical: 'bottom' } });
    // };
    const formik = useFormik({
        initialValues: {
            "no": "",
            "vehicle_id": "",
            "brand": "",
            "vehicle_number": "",
            "cassie_position": "",
            "engine_number": "",
            "owner": "",
            "business": "",
            "datetime": "",
            "detail": "",
            "color": "",
            "seat": "",
            "size": "",
            "standard": "",
            "fuel": "",
            "tax_last_datetime": "",
            "condition": "",
            "tax": "",
            "lat": "",
            "lng": "",
            "status": "",
            "driver_id": "",
            "vehicle_type": "",
            "std": "",
            "seat_type": "",
            "fname_lname": "",
            "opt": ""
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            console.log('values', values)
            // onClose()
            // const req = {
            //     "database": "booking_prd",
            //     "data": aesEncryptECBHex(`INSERT INTO if_vehicle(no,vehicle_id,brand,vehicle_number,cassie_position,engine_number,owner,business,datetime,detail,color,seat,size,standard,fuel,tax_last_datetime,condition,tax,lat,lng,status,driver_id,vehicle_type,std,seat_type,opt) VALUES((SELECT MAX(no) + 1 FROM if_vehicle),'${values.vehicle_id}','${values.brand}','${values.vehicle_number}','${values.cassie_position}','${values.engine_number}','${values.owner}','${values.business}','${values.datetime}','${values.detail}','${values.color}','${values.seat}','${values.size}','${values.standard}','${values.fuel}','${values.tax_last_datetime}','${values.condition}','${values.tax}','${values.lat}','${values.lng}','${values.status}','${values.driver_id}','${values.vehicle_type}','${values.std}','${values.seat_type}','${values.opt}');`)
            // }
            // aesApi.post("https://sqlquery.sitbooking.com/query", req)
            //     .then(res => {
            //         onClose()
            //         getData()
            //     })
            //     .catch(error => {
            //         console.log('error', error)
            //     })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, submitForm, setFieldValue } = formik;


    return (
        <div>
            <Dialog onClose={onClose} open={open}>
                <DialogTitle>สร้างข้อมูลรถ</DialogTitle>
                <DialogContent>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={1} marginTop={"5px"}>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ทะเบียนรถ"
                                        {...getFieldProps('vehicle_id')}
                                        error={Boolean(touched.vehicle_id && errors.vehicle_id)}
                                        helperText={touched.vehicle_id && errors.vehicle_id}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ยี่ห้อ"
                                        {...getFieldProps('brand')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="เลขตัวรถ"
                                        {...getFieldProps('vehicle_number')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ตำแหน่งคัสซี"
                                        {...getFieldProps('cassie_position')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="เลขเครื่องยนต์"
                                        {...getFieldProps('engine_number')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ผู้ถือกรรมสิทธิ์"
                                        {...getFieldProps('owner')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ประกอบการ"
                                        {...getFieldProps('business')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="วันจดทะเบียน"
                                        {...getFieldProps('detail')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ประเภทรถ"
                                        {...getFieldProps('vehicle_type')}
                                        select
                                    >
                                        {
                                            carsOption.car_type.map((car_type, index) => {
                                                return (
                                                    <MenuItem value={car_type.car_type_name}>
                                                        {car_type.car_type_name}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="มาตราฐานรถ"
                                        {...getFieldProps('std')}
                                        select
                                    >
                                        {
                                            carsOption.car_standard.map((car_standard, index) => {
                                                return (
                                                    <MenuItem value={car_standard.car_standard_name}>
                                                        {car_standard.car_standard_name}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="สี"
                                        {...getFieldProps('color')}
                                        select
                                    >
                                        {
                                            carsOption.car_color.map((car_color, index) => {
                                                return (
                                                    <MenuItem value={car_color.car_color_name}>
                                                        {car_color.car_color_name}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ที่นั่ง"
                                        {...getFieldProps('seat')}
                                        select
                                    >
                                        {
                                            carsOption.car_seat_size.map((car_seat_size, index) => {
                                                return (
                                                    <MenuItem value={car_seat_size.car_seat_size_name}>
                                                        {car_seat_size.car_seat_size_name}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="เบาะที่นั่ง"
                                        {...getFieldProps('seat_type')}
                                        select
                                    >
                                        {
                                            carsOption.car_seat_type.map((car_seat_type, index) => {
                                                return (
                                                    <MenuItem value={car_seat_type.car_seat_type_name}>
                                                        {car_seat_type.car_seat_type_name}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ขนาดรถ"
                                        {...getFieldProps('size')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="เชื้อเพลิง"
                                        {...getFieldProps('fuel')}
                                        select
                                    >
                                        {
                                            carsOption.car_fuel.map((car_fuel, index) => {
                                                return (
                                                    <MenuItem value={car_fuel.car_fuel_name}>
                                                        {car_fuel.car_fuel_name}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </Grid>


                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="พนักงานขับประจำ"
                                        {...getFieldProps('driver_id')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ชื่อพนักงานขับประจำ"
                                        disabled
                                        {...getFieldProps('fname_lname')}
                                    />
                                </Grid>


                                <Grid item xs={12} md={12}>
                                    {
                                        carsOption.car_option.length > 0 && (
                                            <div>
                                                <Typography variant="subtitle1">{`ออฟชั่นรถ`}</Typography>
                                                <FormGroup row>
                                                    {carsOption.car_option.map((option) => (
                                                        <FormControlLabel
                                                            key={option.car_option_id}
                                                            control={
                                                                <Checkbox
                                                                    checked={formik.values?.opt?.split(",")?.filter(opt => opt === option?.car_option_name).length > 0}
                                                                    onChange={(e) => {
                                                                        const { name, checked } = e.target;
                                                                        if (checked) {
                                                                            const opt = formik.values?.opt === '' ? [] : formik.values?.opt?.split(",")
                                                                            const s_opt = opt?.concat(option.car_option_name).join(",")
                                                                            formik.setFieldValue("opt", s_opt)

                                                                        } else {
                                                                            const opt = formik.values?.opt?.split(",")
                                                                            const s_opt = opt?.filter(v => v !== option.car_option_name).join(",")
                                                                            formik.setFieldValue("opt", s_opt)
                                                                        }
                                                                    }
                                                                    }
                                                                    name={`opt`}
                                                                />
                                                            }
                                                            label={option.car_option_name}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </div>
                                        )
                                    }
                                    {/* <TextField size="small" fullWidth label="ออฟชั่นรถ"
                                        multiline
                                        rows={4}
                                        {...getFieldProps('opt')}
                                        disabled
                                    /> */}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="วันครบกำหนดเสียภาษี"
                                        {...getFieldProps('tax_last_datetime')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="เงื่อนไข"
                                        {...getFieldProps('condition')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="ค่าภาษี"
                                        {...getFieldProps('tax')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="มาตราฐานขนส่ง"
                                        {...getFieldProps('standard')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" fullWidth label="สถานะ"
                                        {...getFieldProps('status')}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={submitForm} variant="outlined">Save</Button>
                    <Button onClick={onClose} variant="outlined">Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
