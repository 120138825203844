import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SettingCartype from '../SettingCartype/SettingCartype';
import SettingCarStandard from '../SettingCarStandard/SettingCarStandard';
import SettingCarFuel from '../SettingCarFuel/SettingCarFuel';
import SettingCarColor from '../SettingCarColor/SettingCarColor';
import SettingCarSeatSize from '../SettingCarSeatSize/SettingCarSeatSize';
import SettingCarSeatType from '../SettingCarSeatType/SettingCarSeatType';
import SettingCarOption from '../SettingCarOption/SettingCarOption';

export default function VehicleDetails() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="">
                        <Tab label="ประเภทรถ" value="1" />
                        <Tab label="มาตราฐานรถ" value="2" />
                        <Tab label="ประเภทเชื้อเพลิง" value="3" />
                        <Tab label="สีรถ" value="4" />
                        <Tab label="ขนาดที่นั่ง" value="5" />
                        <Tab label="วัสดุที่นั่ง" value="6" />
                        <Tab label="option" value="7" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <SettingCartype />
                </TabPanel>
                <TabPanel value="2">
                    <SettingCarStandard />
                </TabPanel>
                <TabPanel value="3">
                    <SettingCarFuel />
                </TabPanel>
                <TabPanel value="4">
                    <SettingCarColor />
                </TabPanel>
                <TabPanel value="5">
                    <SettingCarSeatSize />
                </TabPanel>
                <TabPanel value="6">
                    <SettingCarSeatType />
                </TabPanel>
                <TabPanel value="7">
                    <SettingCarOption />
                </TabPanel>
            </TabContext>
        </Box>
    );
}