import React from 'react'
import cars from './vanjson.json'
import { aesApi, aesEncryptECBHex } from '../../../query/QueryAES'

export default function SettingCarsUp() {

    const onClick = () => {

        const req = {
            "database": "booking_prd",
            "data": aesEncryptECBHex(`UPDATE if_vehicle SET fuel = 'น้ำมัน' WHERE fuel = 'ดีเซล' ;`)
        }
        aesApi.post("https://sqlquery.sitbooking.com/query", req)
            .then(res => {
            })
            .catch(error => {

            })

        // cars.map(car => {
        //     const req = {
        //         "database": "booking_prd",
        //         "data": aesEncryptECBHex(`UPDATE if_vehicle SET color = '${car.color}' ,  seat = '${car.seat}' ,  fuel = '${car.fuel}', vehicle_type = 'VAN',  std = '${car.std}' ,  seat_type = '${car.seat_type}' , opt = '${car.opt}' WHERE vehicle_id = '${car.vehicle_id}' ;`)
        //     }
        //     aesApi.post("https://sqlquery.sitbooking.com/query", req)
        //         .then(res => {
        //             console.log('car', car)
        //         })
        //         .catch(error => {

        //         })
        // })
    }

    return (
        <div><button onClick={onClick} >อัพเดท</button></div>
    )
}
