import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, TableContainer, Paper } from '@mui/material'
import axios from 'axios'
import EditNoteIcon from '@mui/icons-material/EditNote';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SettingCarFuelDialogCreate from './SettingCarFuelDialogCreate';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';
import SettingCarFuelDialogUpdate from './SettingCarFuelUpdate';
import _ from 'lodash';
import { aesApi, aesEncryptECBHex } from '../../../query/QueryAES';
import { TableVirtuoso } from 'react-virtuoso';
import PreviewIcon from '@mui/icons-material/Preview';
import { useNavigate, useParams } from 'react-router-dom';

export default function SettingCarFuel() {
    const [events, setEvents] = useState([])
    const [openCreate, setOpenCreate] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(false)
    const [update, setUpdateData] = useState({})
    const [search, setSearch] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        axios.get(`https://api.sitbooking.com/booking/car_fuel`)
            .then(res => {
                setEvents(res.data)
            })
            .catch(err => {
                console.log('err', err)
            })
    }, [])

    const getIfEvents = () => {
        axios.get(`https://api.sitbooking.com/booking/car_fuel`)
            .then(res => {
                setEvents(res.data)
            })
            .catch(err => {
                console.log('err', err)
            })
    }
    const onCloseCreate = () => {
        setOpenCreate(false)
    }
    const onOpenCreate = () => {
        setOpenCreate(true)
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = ({ message, variant }) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });
    };
    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };


    const onHandleDelEvents = ({ event }) => {
        console.log('event', event)
        const req = {
            "database": "booking_prd",
            "data": aesEncryptECBHex(`DELETE FROM if_employee WHERE userid = '${event.userid}';`)
        }
        aesApi.post("https://sqlquery.sitbooking.com/query", req)
            .then(res => {
                handleClickVariant({ message: "ลบข้อมูลสำเร็จ", variant: "success" })
                getIfEvents()
            })
    }

    const onCloseUpdate = () => {
        setOpenUpdate(false)
        setUpdateData({})
    }
    const onOpenUpdate = ({ data }) => {
        setOpenUpdate(true)
        setUpdateData(data)
    }

    const handleChange = (e) => {
        const { value, name } = e.target
        setSearch(value)
    }

    return (
        <Box>
            <SettingCarFuelDialogCreate open={openCreate} onClose={onCloseCreate} getData={getIfEvents} />
            <SettingCarFuelDialogUpdate data={update} open={openUpdate} onClose={onCloseUpdate} getData={getIfEvents} />
            <Box padding="10px">
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <TextField size="small" label='ค้นหา'
                        value={search}
                        onChange={handleChange}
                    />
                    <IconButton onClick={onOpenCreate}><ControlPointIcon /></IconButton>
                </Stack>
            </Box>
            <Box sx={{ height: "calc( 100vh - 80px )", maxHeight: "calc( 100vh - 80px )" }}>
                <TableVirtuoso
                    data={_.orderBy(events, ["car_fuel_name"], ["asc"]).filter((val) => val?.car_fuel_name?.toUpperCase().includes(search.toUpperCase()))}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={fixedHeaderContent}
                    itemContent={(_index, row) => rowContent({ _index, row, onHandleDelEvents, onOpenUpdate, handleSnackWithAction, navigate })}
                />
            </Box>
        </Box>
    )
}


function rowContent({ _index, row, onHandleDelEvents, onOpenUpdate, handleSnackWithAction, navigate }) {
    return (
        <React.Fragment>
            <TableCell>{row.car_fuel_name}</TableCell>
            <TableCell
                align="right"
            >
                <Stack direction="row" justifyContent="end">
                    {/* <IconButton size="small" onClick={() => {
                        navigate(`../settings/car_type/car_color/${row.car_fuel_id}`)
                    }}><PreviewIcon fontSize="small" /></IconButton> */}
                    <IconButton size="small" onClick={() => onOpenUpdate({ data: row })}><EditNoteIcon fontSize="small" /></IconButton>
                    <IconButton size="small"
                        onClick={() => {
                            handleSnackWithAction({
                                message: "ต้องการลบข้อมูล",
                                onHandle: () => onHandleDelEvents({ event: row })
                            })
                        }}
                    ><DeleteForeverIcon fontSize="small" /></IconButton>
                </Stack>
            </TableCell>
        </React.Fragment>
    );
}

function fixedHeaderContent() {
    return (
        <TableRow>
            <TableCell sx={{ backgroundColor: 'background.paper' }}>ชื่อประเภทเชื้อเพลิง</TableCell>
            <TableCell sx={{ backgroundColor: 'background.paper' }}></TableCell>
        </TableRow>
    );
}

const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
    TableRow,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};