import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import _ from 'lodash'
const CheckboxFilter = ({ onHandle, data, option }) => {

    const [carsOption, setCarsOption] = useState({
        car_type: [],
        car_standard: [],
        car_seat_type: [],
        car_seat_size: [],
        car_option: [],
        car_fuel: [],
        car_color: [],
        car_color: []
    });

    const [selectedcar, setselectedcar] = useState({
        car_type: '',
        car_standard: '',
        car_seat_type: '',
        car_seat_size: '',
        car_option: [],
        car_fuel: '',
        car_color: ''
    });




    useEffect(() => {
        setselectedcar({ ...selectedcar, ...data })
    }, [data])

    // useEffect(() => {
    //     setCarsOption(option)
    // }, [option])



    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setselectedcar({
            car_type: '',
            car_standard: '',
            car_seat_type: '',
            car_seat_size: '',
            car_option: [],
            car_fuel: '',
            car_color: ''
        });
    };


    useEffect(() => {
        if (open) {
            axios.get(`https://api.sitbooking.com/booking/get_car_options`)
                .then(res => {
                    setCarsOption({
                        ...res.data
                    })
                })
                .catch(err => {
                    console.log('err', err)
                })
        }
    }, [open])


    const handleCheckbox = (e, value) => {
        const { name, checked } = e.target;
        if (checked) {
            setselectedcar(prev => {
                return {
                    ...prev,
                    [name]: value
                };
            });
        } else {
            setselectedcar(prev => {
                return {
                    ...prev,
                    [name]: ''
                };
            });
        }
    };


    const handleCheckboxOpt = (e, value) => {
        const { name, checked } = e.target;
        if (checked) {
            setselectedcar(prev => {
                return {
                    ...prev,
                    [name]: prev[name].concat(value)
                };
            });
        } else {
            setselectedcar(prev => {
                return {
                    ...prev,
                    [name]: prev[name].filter(d => d !== value)
                };
            });
        }
    };

    return (
        <div>
            <IconButton onClick={handleOpen} ><FilterAltTwoToneIcon /></IconButton>
            <Dialog
                open={open}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de", opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>รายละเอียดรถที่ต้องการ</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setOpen(false) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        car_seat_size: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={1}>
                        {
                            carsOption.car_type.length > 0 && (
                                <Grid item xs={3}>
                                    <div>
                                        <Typography variant="subtitle1">{`ประเภทรถ`}</Typography>
                                        <FormGroup>
                                            {carsOption.car_type.map((option) => (
                                                <FormControlLabel
                                                    key={option.car_type_id}
                                                    control={
                                                        <Checkbox
                                                            checked={selectedcar.car_type === option?.car_type_name}
                                                            // disabled={selectedcar.car_type !== '' && selectedcar.car_type?.car_type_id !== option?.car_type_id}
                                                            onChange={(e) =>
                                                                handleCheckbox(e, option.car_type_name)
                                                            }
                                                            // value={option}
                                                            name={`car_type`}
                                                        />
                                                    }
                                                    label={option.car_type_name}
                                                />
                                            ))}
                                        </FormGroup>
                                    </div>
                                </Grid>
                            )
                        }

                        {
                            carsOption.car_standard.length > 0 && (
                                <Grid item xs={3}>
                                    <div>
                                        <Typography variant="subtitle1">{`มาตราฐานรถ`}</Typography>
                                        <FormGroup>
                                            {carsOption.car_standard.map((option) => (
                                                <FormControlLabel
                                                    key={option.car_standard_id}
                                                    control={
                                                        <Checkbox
                                                            checked={selectedcar.car_standard === option?.car_standard_name}
                                                            // disabled={selectedcar.car_type !== '' && selectedcar.car_type?.car_type_id !== option?.car_type_id}
                                                            onChange={(e) =>
                                                                handleCheckbox(e, option.car_standard_name)
                                                            }
                                                            name={`car_standard`}
                                                        />
                                                    }
                                                    label={option.car_standard_name}
                                                />
                                            ))}
                                        </FormGroup>
                                    </div>
                                </Grid>
                            )
                        }


                        {
                            carsOption.car_fuel.length > 0 && (
                                <Grid item xs={3}>
                                    <div>
                                        <Typography variant="subtitle1">{`ประเภทเชื้อเพลิง`}</Typography>
                                        <FormGroup>
                                            {carsOption.car_fuel.map((option) => (
                                                <FormControlLabel
                                                    key={option.car_fuel_id}
                                                    control={
                                                        <Checkbox
                                                            checked={selectedcar.car_fuel === option?.car_fuel_name}
                                                            // disabled={selectedcar.car_type !== '' && selectedcar.car_type?.car_type_id !== option?.car_type_id}
                                                            onChange={(e) =>
                                                                handleCheckbox(e, option.car_fuel_name)
                                                            }
                                                            name={`car_fuel`}
                                                        />
                                                    }
                                                    label={option.car_fuel_name}
                                                />
                                            ))}
                                        </FormGroup>
                                    </div>
                                </Grid>
                            )
                        }


                        {
                            carsOption.car_color.length > 0 && (
                                <Grid item xs={3}>
                                    <div>
                                        <Typography variant="subtitle1">{`สีรถ`}</Typography>
                                        <FormGroup>
                                            {carsOption.car_color.map((option) => (
                                                <FormControlLabel
                                                    key={option.car_color_id}
                                                    control={
                                                        <Checkbox
                                                            checked={selectedcar.car_color === option?.car_color_name}
                                                            // disabled={selectedcar.car_type !== '' && selectedcar.car_type?.car_type_id !== option?.car_type_id}
                                                            onChange={(e) =>
                                                                handleCheckbox(e, option.car_color_name)
                                                            }
                                                            name={`car_color`}
                                                        />
                                                    }
                                                    label={option.car_color_name}
                                                />
                                            ))}
                                        </FormGroup>
                                    </div>
                                </Grid>
                            )
                        }


                        {
                            carsOption.car_seat_size.length > 0 && (
                                <Grid item xs={3}>
                                    <div>
                                        <Typography variant="subtitle1">{`ขนาดที่นั่ง`}</Typography>
                                        <FormGroup>
                                            {/* {_.orderBy(carsOption.car_seat_size, ["car_seat_size_name"], ["asc"]).map((option) => ( */}
                                            {_.orderBy(carsOption.car_seat_size, ["car_seat_size_name"], ["asc"]).map((option) => (
                                                <FormControlLabel
                                                    key={option.car_seat_size_id}
                                                    control={
                                                        <Checkbox
                                                            checked={selectedcar.car_seat_size === option?.car_seat_size_name}
                                                            // disabled={selectedcar.car_type !== '' && selectedcar.car_type?.car_type_id !== option?.car_type_id}
                                                            onChange={(e) =>
                                                                handleCheckbox(e, option.car_seat_size_name)
                                                            }
                                                            name={`car_seat_size`}
                                                        />
                                                    }
                                                    label={option.car_seat_size_name}
                                                />
                                            ))}
                                        </FormGroup>
                                    </div>
                                </Grid>
                            )
                        }
                        {
                            carsOption.car_seat_type.length > 0 && (
                                <Grid item xs={3}>
                                    <div>
                                        <Typography variant="subtitle1">{`วัสดุที่นั่ง`}</Typography>
                                        <FormGroup>
                                            {carsOption.car_seat_type.map((option) => (
                                                <FormControlLabel
                                                    key={option.car_seat_type_id}
                                                    control={
                                                        <Checkbox
                                                            checked={selectedcar.car_seat_type === option?.car_seat_type_name}
                                                            // disabled={selectedcar.car_type !== '' && selectedcar.car_type?.car_type_id !== option?.car_type_id}
                                                            onChange={(e) =>
                                                                handleCheckbox(e, option.car_seat_type_name)
                                                            }
                                                            name={`car_seat_type`}
                                                        />
                                                    }
                                                    label={option.car_seat_type_name}
                                                />
                                            ))}
                                        </FormGroup>
                                    </div>
                                </Grid>
                            )
                        }

                        {
                            carsOption.car_option.length > 0 && (
                                <Grid item xs={3}>
                                    <div>
                                        <Typography variant="subtitle1">{`ออฟชั่นรถ`}</Typography>
                                        <FormGroup>
                                            {carsOption.car_option.map((option) => (
                                                <FormControlLabel
                                                    key={option.car_option_id}
                                                    control={
                                                        <Checkbox
                                                            checked={selectedcar.car_option?.filter(opt => opt === option?.car_option_name).length > 0}
                                                            // disabled={selectedcar.car_type !== '' && selectedcar.car_type?.car_type_id !== option?.car_type_id}
                                                            onChange={(e) =>
                                                                handleCheckboxOpt(e, option.car_option_name)
                                                            }
                                                            name={`car_option`}
                                                        />
                                                    }
                                                    label={option.car_option_name}
                                                />
                                            ))}
                                        </FormGroup>
                                    </div>
                                </Grid>
                            )
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={
                        () => {
                            console.log('selectedcar', selectedcar)
                            onHandle(selectedcar)
                            handleClose()
                        }}
                    >
                        ยืนยัน
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default CheckboxFilter;
