import React, { useEffect, useState } from 'react';
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, MenuItem, CircularProgress, Backdrop } from '@mui/material'
import { fDateTo } from '../../utils/formatTime';
import _, { values } from 'lodash';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import TableCars from './TableCars';
import TableEmployee from './TableEmployee';
import { useNavigate } from 'react-router-dom';
import ArrowCircleLeftTwoToneIcon from '@mui/icons-material/ArrowCircleLeftTwoTone';
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone';
import { aesApi, aesEncryptECBHex } from '../../query/QueryAES';

import FilterListIcon from '@mui/icons-material/FilterList';
import CheckboxFilter from './CheckboxFilter';

const OverviewBooking = ({ userId }) => {
  const [page, setPage] = useState(1)
  const [pageMax, setPageMax] = useState(1)
  const [totalRows, setTotalRows] = useState(0)

  const [initialData, setInitialData] = useState([])
  const [loading, setloading] = useState(true)
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, "0"))
  const [search, setSearch] = useState("")
  const [carSelect, setCarSelect] = useState("")
  const [carFilter, setCarFilter] = useState({
    car_type: '',
    car_standard: '',
    car_seat_type: '',
    car_seat_size: '',
    car_option: [],
    car_fuel: '',
    car_color: ''
  })
  const [typeCar, setTypeCar] = useState([])
  const [mode, setMode] = useState("car")
  const [floading, setfloading] = useState(false)


  const navigate = useNavigate()
  // const [carsOption, setCarsOption] = useState({
  //   car_type: [],
  //   car_standard: [],
  //   car_seat_type: [],
  //   car_seat_size: [],
  //   car_option: [],
  //   car_fuel: [],
  //   car_color: [],
  //   car_color: []
  // });



  const [timerReference, setTimerReference] = useState(undefined);
  const [searchReference, setSearchReference] = useState('');

  useEffect(() => {
    // if (carFilter.car_type !== '') {
    if (floading) {
      setloading(true)
    }
    const date_start = `${year}-${month}-01`
    const date_end = `${year}-${month}-${calculateMaxDate()}`
    const url = mode === "car" ?
      `https://api.sitbooking.com/booking/get_work_car_opt?search=${search}${carFilter.car_type !== '' ? `&car_type=${carFilter.car_type}` : ``}${carFilter.car_standard !== '' ? `&car_standard=${carFilter.car_standard}` : ``}${carFilter.car_seat_type !== '' ? `&car_seat_type=${carFilter.car_seat_type}` : ``}${carFilter.car_seat_size !== '' ? `&car_seat_size=${carFilter.car_seat_size}` : ``}${carFilter.car_option.length > 0 ? `&car_option=${carFilter.car_option.map(v => v).join(',')}` : ``}${carFilter.car_fuel !== '' ? `&car_fuel=${carFilter.car_fuel}` : ``}${carFilter.car_color !== '' ? `&car_color=${carFilter.car_color}` : ``}&page=${page}&pageSize=12&date_start=${date_start}&date_end=${date_end}`
      :
      `https://api.sitbooking.com/booking/get_work_employee?page=${page}&size=15&date_start=${date_start}&date_end=${date_end}&search=${search}`
    axios.get(url)
      .then(res => {
        const iFllter = res.data.data
        if (mode === "car") {
          const datatoset = iFllter.map(d => {
            const bookings = d.booking || []
            const events = d.event || []

            const databookings = bookings.map(v => {
              return {
                ...v,
                "date_start": (v.reserve_datetime_start.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.reserve_datetime_end.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
              }
            })
            const dataevents = events.map(v => {
              return {
                ...v,
                "date_start": (v.date_start.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.date_end.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),

              }
            })
            return {
              "vehicle_id": d.vehicle_id,
              "userid": d.userid,
              "fname_lname": d.fname_lname,
              "phone": d.phone,
              "color": d.color,
              "seat": d.seat,
              "size": d.size,
              "fuel": d.fuel,
              "data": databookings.concat(dataevents)
            }
          })
          setInitialData(datatoset)
        } else {
          const datatoset = iFllter.map(d => {
            const bookings = d.booking || []
            const events = d.event || []

            const databookings = bookings.map(v => {
              return {
                ...v,
                "date_start": (v.reserve_datetime_start.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.reserve_datetime_end.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
              }
            })
            const dataevents = events.map(v => {
              return {
                ...v,
                "date_start": (v.date_start.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.date_end.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
              }
            })

            return {
              "userid": d.userid,
              "fname_lname": d.fname_lname,
              "position": d.position,
              "department": d.department,
              "department2": d.department2,
              "data": databookings.concat(dataevents)
            }
          })
          setInitialData(datatoset)
        }



        setPageMax(res.data.pagesize)
        setTotalRows(res.data.totalRows)
        // setSearch("")
        setloading(false)
        setfloading(false)
      })
      .catch(error => {
        setloading(false)
        setfloading(false)
      })
    // }
  }, [page, mode, month, search, carFilter])

  const getDataBnE = () => {
    // if (carFilter.car_type !== '') {
    if (floading) {
      setloading(true)
    }
    const date_start = `${year}-${month}-01`
    const date_end = `${year}-${month}-${calculateMaxDate()}`
    const url = mode === "car" ?
      `https://api.sitbooking.com/booking/get_work_car_opt?search=${search}${carFilter.car_type !== '' ? `&car_type=${carFilter.car_type}` : ``}${carFilter.car_standard !== '' ? `&car_standard=${carFilter.car_standard}` : ``}${carFilter.car_seat_type !== '' ? `&car_seat_type=${carFilter.car_seat_type}` : ``}${carFilter.car_seat_size !== '' ? `&car_seat_size=${carFilter.car_seat_size}` : ``}${carFilter.car_option.length > 0 ? `&car_option=${carFilter.car_option.map(v => v).join(',')}` : ``}${carFilter.car_fuel !== '' ? `&car_fuel=${carFilter.car_fuel}` : ``}${carFilter.car_color !== '' ? `&car_color=${carFilter.car_color}` : ``}&page=${page}&pageSize=12&date_start=${date_start}&date_end=${date_end}`
      :
      `https://api.sitbooking.com/booking/get_work_employee?page=${page}&size=15&date_start=${date_start}&date_end=${date_end}&search=${search}`
    axios.get(url)
      .then(res => {
        const iFllter = res.data.data
        if (mode === "car") {
          const datatoset = iFllter.map(d => {
            const bookings = d.booking || []
            const events = d.event || []

            const databookings = bookings.map(v => {
              return {
                ...v,
                "date_start": (v.reserve_datetime_start.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.reserve_datetime_end.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
              }
            })
            const dataevents = events.map(v => {
              return {
                ...v,
                "date_start": (v.date_start.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.date_end.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),

              }
            })
            return {
              "vehicle_id": d.vehicle_id,
              "userid": d.userid,
              "fname_lname": d.fname_lname,
              "phone": d.phone,
              "color": d.color,
              "seat": d.seat,
              "size": d.size,
              "fuel": d.fuel,
              "data": databookings.concat(dataevents)
            }
          })
          setInitialData(datatoset)
        } else {
          const datatoset = iFllter.map(d => {
            const bookings = d.booking || []
            const events = d.event || []

            const databookings = bookings.map(v => {
              return {
                ...v,
                "date_start": (v.reserve_datetime_start.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.reserve_datetime_end.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
              }
            })
            const dataevents = events.map(v => {
              return {
                ...v,
                "date_start": (v.date_start.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.date_end.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
              }
            })

            return {
              "userid": d.userid,
              "fname_lname": d.fname_lname,
              "position": d.position,
              "department": d.department,
              "department2": d.department2,
              "data": databookings.concat(dataevents)
            }
          })
          setInitialData(datatoset)
        }
        setPageMax(res.data.pagesize)
        setTotalRows(res.data.totalRows)
        // setSearch("")
        setloading(false)
        setfloading(false)
      })
      .catch(error => {
        setloading(false)
        setfloading(false)
      })
    // }
  }

  const handleEventClick = (event) => {
    navigate("../create", { state: event })
  };

  const calculateMaxDate = () => {
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    return lastDayOfMonth
  };

  const onSearch = (e) => {
    setSearch(e.target.value)
    setPage(1)
  }

  const initialdataFillter = initialData
  // const initialdataFillter = mode === "car" ? initialData.filter((c, i) => c.vehicle_id.toLowerCase().includes(search.toLowerCase())) : initialData.filter((c, i) => c.userid.toLowerCase().includes(search.toLowerCase()))



  if (loading) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (
    <Box sx={{ margin: "10px" }}>
      <Box>
        <Stack direction="row" spacing={1}>
          <TextField size="small" label="ค้นหา" onChange={onSearch} value={search} />
          {/* <IconButton ><FilterListIcon /></IconButton> */}
          {
            mode === "car" && (
              <CheckboxFilter data={carFilter} onHandle={(d) => {
                setCarFilter(d)
                setPage(1)
              }} />
            )
          }
          <ButtonGroup
            disableElevation
            variant="outlined"
            aria-label="buttongroup mode"
            color="success"
          >
            <Button variant={mode === "car" ? "contained" : "outlined"}
              disabled={mode === "car"}
              onClick={() => {
                setMode("car")
                setPage(1)
                setSearch('')
                setInitialData([])
              }}>รถ</Button>
            <Button variant={mode === "employee" ? "contained" : "outlined"}
              disabled={mode === "employee"}
              onClick={() => {
                setMode("employee")
                setPage(1)
                setSearch('')
                setInitialData([])
              }}>พนักงาน</Button>
          </ButtonGroup>
          <TextField size="small"
            type="month"
            value={`${year}-${(month)}`}
            onChange={(e) => {
              const d = e.target.value
              const yearNmonth = d.split("-")
              setYear(yearNmonth[0])
              setMonth(yearNmonth[1])
              setPage(1)
            }} />
          <Typography variant="subtitle1" sx={{ padding: "5px" }}> จำนวน {totalRows} รายการ </Typography>
        </Stack>
      </Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 70px)" }}>
          <Table stickyHeader size="small"
            style={{ border: "none", userSelect: "none" }}
            sx={{
              "& .MuiTableCell-root": {
                borderLeft: "1px solid rgba(224, 224, 224, 1)",
                // backgroundColor: '#d3d3d3'
                // backgroundColor: '#F9F9F9'
              }
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 2, textAlign: "center", minWidth: "180px", maxWidth: "180px" }}> {mode === "car" ? "ทะเบียนรถ" : "รหัสพนักงาน"}</TableCell>
                <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 2, textAlign: "center", borderLeft: "0px" }} colSpan={calculateMaxDate()} >
                  {`${month}/${year}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 3 }}>
                  <Stack spacing={0.2} direction="row" justifyContent="space-between">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setPage(p => { return p - 1 })
                      }}
                      disabled={page === 1 || pageMax == 0}
                    >
                      <ArrowCircleLeftTwoToneIcon fontSize="small" />
                    </IconButton>
                    {/* {
                      mode === "car" && (
                        <TextField select value={carFilter} size="small"
                          onChange={(e) => {
                            setCarFilter(e.target.value)
                            setPage(1)
                          }}
                          sx={{ fontSize: "12px" }}
                        >
                          {
                            typeCar.map((t, i) => {
                              return (
                                <MenuItem id={`typeCar-${i}`} value={t}>
                                  {`${t.color}/${t.seat}/${t.fuel}`}
                                </MenuItem>
                              )
                            })
                          }
                        </TextField>
                      )
                    } */}
                    <IconButton
                      onClick={() => {
                        setPage(p => { return p + 1 })
                      }}
                      disabled={page === pageMax || pageMax == 0}
                      size="small"
                    >
                      <ArrowCircleRightTwoToneIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                </TableCell>
                {
                  [...Array(calculateMaxDate())].map((value, index) => {
                    return (
                      <TableCell key={`Table-TableCell-${index}`} style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 2 }}>{`${(index + 1).toString().padStart(2, "0")}`}</TableCell>
                    )
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                mode === "car" && (
                  <TableCars typeCar={carFilter} userId={userId} getDataBnE={getDataBnE} dataFillter={initialdataFillter} handleEventClick={handleEventClick} maxDate={calculateMaxDate()} year={year} month={month} />
                )
              }
              {
                mode === "employee" && (
                  <TableEmployee userId={userId} getDataBnE={getDataBnE} dataFillter={initialdataFillter} handleEventClick={handleEventClick} maxDate={calculateMaxDate()} year={year} month={month} />
                )
              }
            </TableBody>
          </Table>
        </TableContainer >
      </Paper >
    </Box >
  );
};

export default OverviewBooking;
